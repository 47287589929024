import React from 'react';
import { scroller } from 'react-scroll';
import './Card_Erro.css';

const Card_Erro = ({ message, resetFilters }) => {
    const scrollToFilter = () => {
        scroller.scrollTo('filter', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        });
        resetFilters(); // Reseta os filtros quando o botão é clicado
    };

    return (
        <div className="card-error">
            <h3>{message}</h3>
            <button onClick={scrollToFilter} className='button'>
                Voltar à busca Avançada
                <span>
                    <svg width="17" height="17" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.3138 17.7652L15.6059 14.0682C16.8022 12.5441 17.4513 10.662 17.4489 8.72446C17.4489 6.99893 16.9372 5.31214 15.9786 3.87741C15.0199 2.44268 13.6574 1.32445 12.0632 0.664114C10.469 0.00378014 8.71479 -0.168993 7.02241 0.167642C5.33003 0.504277 3.77548 1.3352 2.55534 2.55534C1.3352 3.77548 0.504277 5.33003 0.167642 7.02241C-0.168993 8.71479 0.00378014 10.469 0.664114 12.0632C1.32445 13.6574 2.44268 15.0199 3.87741 15.9786C5.31214 16.9372 6.99893 17.4489 8.72446 17.4489C10.662 17.4513 12.5441 16.8022 14.0682 15.6059L17.7652 19.3138C17.8666 19.416 17.9872 19.4971 18.1201 19.5525C18.253 19.6079 18.3955 19.6364 18.5395 19.6364C18.6834 19.6364 18.826 19.6079 18.9589 19.5525C19.0918 19.4971 19.2124 19.416 19.3138 19.3138C19.416 19.2124 19.4971 19.0918 19.5525 18.9589C19.6079 18.826 19.6364 18.6834 19.6364 18.5395C19.6364 18.3955 19.6079 18.253 19.5525 18.1201C19.4971 17.9872 19.416 17.8666 19.3138 17.7652V17.7652ZM2.18112 8.72446C2.18112 7.43031 2.56488 6.16522 3.28387 5.08918C4.00286 4.01313 5.02479 3.17445 6.22043 2.6792C7.41607 2.18395 8.73172 2.05437 10.001 2.30685C11.2703 2.55932 12.4362 3.18252 13.3513 4.09762C14.2664 5.01272 14.8896 6.17864 15.1421 7.44792C15.3946 8.71721 15.265 10.0329 14.7697 11.2285C14.2745 12.4241 13.4358 13.4461 12.3598 14.1651C11.2837 14.884 10.0186 15.2678 8.72446 15.2678C6.98906 15.2678 5.32474 14.5784 4.09762 13.3513C2.87051 12.1242 2.18112 10.4599 2.18112 8.72446Z" fill="white"/>
                    </svg>
                </span>
            </button>
        </div>
    );
};

export default Card_Erro;
