import React, { useState, useRef } from 'react';
import Banner from './components/Banner';
import Card from './components/Card';
import Header from './components/Header';
import Sessao from './components/Sessao';
import candidatos_json from './json/novocand.json';
import Card_Erro from './components/Card_Error';
import Footer from './components/Footer';
import Sep from './components/Sep';
import './App.css'

const App = () => {
  const [filtro, setFiltro] = useState({ estado: '', cidade: '' });
  const [cardsVisiveis, setCardsVisiveis] = useState(false);
  const filterRef = useRef(null); // Cria uma referência para o componente Filter

  const handleSaveData = (data) => {
    setFiltro(data);
    setCardsVisiveis(true); 
  };

  const handleStateClick = (estado) => {
    setFiltro({ ...filtro, estado });
    setCardsVisiveis(true);
  };

  // Função para resetar os filtros
  const resetFilters = () => {
    setFiltro({ estado: '', cidade: '' });
    setCardsVisiveis(false); // Esconder os cards de candidatos
    if (filterRef.current) {
      filterRef.current.resetFilters(); // Chamar resetFilters do componente Filter
    }
  };

  const candidatosFiltrados = candidatos_json.filter(candidato =>
    (filtro.estado === '' || candidato.Estado === filtro.estado) &&
    (filtro.cidade === '' || candidato.Cidade === filtro.cidade)
  );

  const candidatosOrdenados = candidatosFiltrados.sort((a, b) => {
    const nomeA = a.Nome || ''; 
    const nomeB = b.Nome || '';  

    return nomeA.localeCompare(nomeB);
  });

  return (
    <div className="App">
      <Header />
      <Banner ref={filterRef} onSave={handleSaveData} /> {/* Passando a referência do Filter */}
      <Sessao onStateClick={handleStateClick} cardsVisiveis={cardsVisiveis} resetFilters={resetFilters}>
        <div className={`card-container ${!cardsVisiveis ? 'disabled' : ''}`}>
          {cardsVisiveis && (
            candidatosOrdenados.length === 0 ? (
              <Card_Erro 
                message="Nenhum candidato encontrado para o filtro selecionado."
                resetFilters={resetFilters}  // Passando a função de reset para o Card_Erro
              />
            ) : (
              candidatosOrdenados.map((candidato, index) => (
                <Card
                  key={index}
                  nome={candidato.Nome}
                  cidade={candidato.Cidade}
                  estado={candidato.Estado}
                  partido={candidato.Partido}
                  genero={candidato["Gênero"]}
                  raca={candidato["Raça"]}
                  faixaEtaria={candidato["Faixa-etária"]}
                  candidato={candidato["Pr\u00e9 Candidato\/a"]}
                  redes={candidato.Redes}
                  selos={candidato.Selos}
                  foto={candidato.Foto}
                />
              ))
            )
          )}
        </div>
      </Sessao>
      <Sep />
      <Footer />
    </div>
  );
}

export default App;
