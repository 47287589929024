import React from 'react';
import './Sessao.css';
import Map from '../Map';
import { scroller } from 'react-scroll';



const Sessao = ({ children, onStateClick, cardsVisiveis,resetFilters}) => {
  const scrollToFilter = () => {
    scroller.scrollTo('filter', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
    });
    resetFilters(); 
};

  return (
    <section className={`all-box ${!cardsVisiveis ? 'centered' : ''}`}>
      <div className='container_map'  id='scroll'>
        <Map onStateClick={onStateClick} />
      </div>
      {cardsVisiveis && (
        <div className="scrolling-box">
          {children}
          <button className="scrolling__box__button"  onClick={scrollToFilter} >Limpar Filtro</button>
        </div>
      )}
    </section>
  );
};

export default Sessao;
